import { getCookieValue, setCookie } from '@klv/shared/utils/cookies';
import { decodeAndParse } from '@klv/shared/utils/google-tag-manager';

import type {
  ListenerPayload,
  ReactSDKClient,
} from '@optimizely/react-sdk';

const EXPERIMENTS_COOKIE_NAME = '_kvyo_mkt_exp';
const ONE_YEAR = 31536000;

interface IDecision {
  experimentId: string;
  flagKey: string;
  reasons?: string[];
  ruleKey: string;
  variables?: {
    [x: string]: string | number | boolean;
  }[];
  variationKey: string;
}

interface IListenerPayload extends ListenerPayload {
  decisionInfo?: Omit<IDecision, 'experimentId'> & {
    decisionEventDispatched: boolean;
    enabled: boolean;
    flagKey: string;
    ruleKey: string;
    userContext?: {
      attributes: {
        [x: string]: string | number | boolean;
      };
      id: string;
    };
    variationKey: string;
  };
  eventKey?: string;
  type?: string;
}

interface IExperimentsCookie {
  sitewide?: IDecision[];
  [x: string]: IDecision | IDecision[] | undefined; // only sitewide is ever an array
}

const CONTENT_SQUARE_INTEGRATION = 'Optimizely Full Stack';

const getOptimizelyExperimentFromRuleKey = (ruleKey: string, optimizely: ReactSDKClient) => {
  const config = optimizely.getOptimizelyConfig();
  const experiment = config?.experimentsMap?.[ruleKey];
  return experiment?.id || false;
};

export const setContentsquareExperimentVariables = (ruleKey: string, variationKey: string) => {
  window?._uxa?.push?.(['trackDynamicVariable', { key: `AB_OP_${ruleKey}`, value: variationKey }]);
};

export const setContentsquareExperimentIntegrations = () => {
  if (window.CS_CONF) {
    window.CS_CONF.integrations = window.CS_CONF.integrations || [];
    if (!window.CS_CONF.integrations.includes(CONTENT_SQUARE_INTEGRATION)) {
      window.CS_CONF.integrations.push(CONTENT_SQUARE_INTEGRATION);
    }
  }
};

const saveDecisionToExperimentsCookie = ({
  experimentId,
  flagKey,
  ruleKey,
  variables,
  variationKey,
}: IDecision) => {
  try {
    const experimentsCookie = getCookieValue(EXPERIMENTS_COOKIE_NAME);
    let experiments: IExperimentsCookie = {};
  
    if (experimentsCookie) {
      experiments = decodeAndParse(experimentsCookie) || {};
    }
  
    experiments.sitewide = experiments?.sitewide || [];

    const experiment = experiments.sitewide.find((storedExperiment) => storedExperiment.experimentId === experimentId);

    if (experiment) {
      return true;
    }
  
    experiments.sitewide.push({
      experimentId,
      flagKey,
      ruleKey,
      variables,
      variationKey,
    });
  
    setCookie({
      cookieName: EXPERIMENTS_COOKIE_NAME,
      data: btoa(JSON.stringify(experiments)),
      maxAge: ONE_YEAR,
    });

    return true;
  } catch {
    console.error('Error saving decision to experiments cookie');
    return false;
  }
};

export const onDecision = ({ decisionInfo }: IListenerPayload, optimizely: ReactSDKClient) => {
  const {
    decisionEventDispatched = false,
    flagKey = '',
    ruleKey = '',
    variables,
    variationKey = '',
  } = decisionInfo || {};

  let disableCallback = false;

  const callback = () => {
    if (!disableCallback) {
      disableCallback = true;
      setContentsquareExperimentIntegrations();
    }
  };

  window?._uxa?.push?.(['afterPageView', callback]);

  if (decisionEventDispatched) {
    setContentsquareExperimentVariables(ruleKey, variationKey);
    saveDecisionToExperimentsCookie({
      experimentId: getOptimizelyExperimentFromRuleKey(ruleKey, optimizely) || '',
      flagKey,
      ruleKey,
      variables,
      variationKey,
    });
  }
};
