import {
  createContext, useContext, useEffect, useMemo, useReducer,
} from 'react';

import { hasStorageAvailable } from '@klv/shared/utils/storage';
import getVisitorGeolocation from './utils';

import type { ReactNode } from 'react';
import type { ILocalizationStore, TGeoFeedContinent } from './types';

const initialState = {
  continent: '',
  country: '',
  geodataLoaded: false,
  state: '',
};

const initialStore = {
  localizationState: initialState,
  replace: () => {},
  reset: () => {},
  setContinent: () => {},
  setCountry: () => {},
  setGeodataLoaded: () => {},
  setState: () => {},
};

export const LocalizationContext = createContext<ILocalizationStore>(initialStore);

export const useLocalization = () => {
  const context = useContext(LocalizationContext);
  if (context === undefined) {
    throw new Error('useLocalization must be used in a child of the LocalizationContextProvider');
  }
  return context;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reducer(state: ILocalizationStore['localizationState'], action: { type: string; payload: any; }) {
  switch (action.type) {
    case 'replace':
      return {
        continent: action.payload?.continent || initialState.continent,
        country: action.payload?.country || initialState.country,
        geodataLoaded: action.payload?.geodataLoaded || initialState.geodataLoaded,
        state: action.payload?.state || initialState.state,
      } as ILocalizationStore['localizationState'];
    case 'reset':
      return initialState;
    case 'setContinent':
      return { ...state, continent: action.payload };
    case 'setCountry':
      return { ...state, country: action.payload };
    case 'setGeodataLoaded':
      return { ...state, geodataLoaded: action.payload };
    case 'setState':
      return { ...state, state: action.payload };
    default:
      throw new Error('Unrecognized action passed to reducer');
  }
}

const LocalizationContextProvider = ({ children }: { children: ReactNode; }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedStore = useMemo(() => ({
    localizationState: state,
    replace: (value: ILocalizationStore['localizationState']) => {
      dispatch({ type: 'replace', payload: value });
    },
    reset: () => {
      dispatch({ type: 'reset', payload: {} });
    },
    setContinent: (value: TGeoFeedContinent) => {
      dispatch({ type: 'setContinent', payload: value });
    },
    setCountry: (value: string) => {
      dispatch({ type: 'setCountry', payload: value });
    },
    setGeodataLoaded: (value: boolean) => {
      dispatch({ type: 'setGeodataLoaded', payload: value });
    },
    setState: (value: string) => {
      dispatch({ type: 'setState', payload: value });
    },
  }), [state]);

  useEffect(() => {
    const setLocalizationData = async () => {
      try {
        const storedData = sessionStorage.getItem('localizationData');
        if ((storedData === null) || !(JSON.parse(storedData)?.geodataLoaded)) {
          const localizationData = await getVisitorGeolocation();
          if (localizationData) {
            memoizedStore.replace(localizationData);
            sessionStorage.setItem('localizationData', JSON.stringify(localizationData));
          }
        } else if (storedData) {
          const parsedData = JSON.parse(storedData);
          if (parsedData && parsedData?.geodataLoaded) {
            memoizedStore.replace(parsedData);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (hasStorageAvailable('sessionStorage')) {
      setLocalizationData();
    }
  }, []);

  return (
    <LocalizationContext.Provider value={memoizedStore}>
      { children }
    </LocalizationContext.Provider>
  );
};

export default LocalizationContextProvider;
