import {
  createContext, useContext, useEffect, useReducer, useMemo,
} from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import { hasStorageAvailable } from '@klv/shared/utils/storage';
import getLoggedInStatus from './utils';

import type { ReactNode } from 'react';
import type { IAuthenticationStore } from './types';

const initialState: IAuthenticationStore['authenticationState'] = {
  accountDataLoaded: false,
};

const initialStore = {
  authenticationState: initialState,
  replace: () => { },
  reset: () => { },
  setAccount: () => { },
  setAccountDataLoaded: () => { },
  setLoggedIn: () => { },
  setPaid: () => { },
  setLandingPageUrl: () => { },
};

export const AuthenticationContext = createContext<IAuthenticationStore>(initialStore);

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('useAuthentication must be used in a child of the AuthenticationContextProvider');
  }
  return context;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reducer(state: IAuthenticationStore['authenticationState'], action: { type: string; payload: any; }) {
  switch (action.type) {
    case 'replace':
      return {
        account: action.payload?.account,
        accountDataLoaded: action.payload?.accountDataLoaded,
        loggedIn: action.payload?.loggedIn || false,
        paid: action.payload?.paid,
        landingPageUrl: action.payload?.landingPageUrl,
      } as IAuthenticationStore['authenticationState'];
    case 'reset':
      return initialState;
    case 'setAccount':
      return { ...state, account: action.payload };
    case 'setAccountDataLoaded':
      return { ...state, accountDataLoaded: action.payload };
    case 'setLoggedIn':
      return { ...state, loggedIn: action.payload };
    case 'setPaid':
      return { ...state, paid: action.payload };
    case 'setLandingPageUrl':
      return { ...state, landingPageUrl: action.payload };
    default:
      throw new Error('Unrecognized action passed to reducer');
  }
}

const AuthenticationContextProvider = ({ children }: { children: ReactNode; }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const memoizedStore = useMemo(() => ({
    authenticationState: state,
    replace: (value: IAuthenticationStore['authenticationState']) => {
      dispatch({ type: 'replace', payload: value });
    },
    reset: () => {
      dispatch({ type: 'reset', payload: {} });
    },
    setAccount: (value: boolean) => {
      dispatch({ type: 'setAccount', payload: value });
    },
    setAccountDataLoaded: (value: boolean) => {
      dispatch({ type: 'setAccountDataLoaded', payload: value });
    },
    setLoggedIn: (value: boolean) => {
      dispatch({ type: 'setLoggedIn', payload: value });
    },
    setPaid: (value: boolean) => {
      dispatch({ type: 'setPaid', payload: value });
    },
    setLandingPageUrl: (value: string) => {
      dispatch({ type: 'setLandingPageUrl', payload: value });
    },
  }), [state]);
  useEffect(() => {
    if (hasStorageAvailable('sessionStorage')) {
      getLoggedInStatus().then((accountAttributes) => {
        if (accountAttributes) {
          memoizedStore.replace({
            ...accountAttributes,
            paid: accountAttributes?.paid || false,
          });
          sessionStorage.setItem('account', accountAttributes.account.toString());
          sessionStorage.setItem('loggedIn', accountAttributes.loggedIn.toString());
        }
      });
    }
  }, [location]);
  return (
    <AuthenticationContext.Provider value={memoizedStore}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
