export const getCookie = (cookieName: string) => {
  if (typeof document === 'undefined') return '';
  return document?.cookie?.split('; ').find((row) => row.startsWith(cookieName));
};

export const setCookie = ({
  cookieName,
  data,
  expiration = '',
  maxAge = '',
  path = '/',
  samesite = 'lax',
}: {
  cookieName: string;
  data: string;
  expiration?: string | number;
  maxAge?: string | number;
  path?: string;
  samesite?: 'lax' | 'none' | 'strict';
}) => {
  document.cookie = `${cookieName}=${data};${expiration && `expires=${expiration};`}${maxAge && `max-age=${maxAge};`}path=${path};samesite=${samesite};secure`;
};

export const clearCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure`;
};

export const getCookieValue = (cookieName: string) => {
  if (getCookie(cookieName)) {
    return getCookie(cookieName)?.split('=')[1];
  }

  return null;
};
