import { Script, ScriptStrategy } from 'gatsby';

export const removeCookie = (name: string) => {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const handleConsent = (groups: string[]) => {
  if (groups.includes('C0003')) {
    removeCookie('_kvyo_mkt_opt_out');
  } else {
    removeCookie('_kvyo_mkt_exp');
    removeCookie('_kvyo_mkt_id');

    const expiration = new Date();
    expiration.setFullYear(expiration.getFullYear() + 1);
    document.cookie = `_kvyo_mkt_opt_out=true; expires = ${expiration.toUTCString()};`;
  }
};

/* @ts-ignore: e is OneTrust CustomEvent with no good types available */
export const onConsentChanged = (e) => {
  if (e && e?.detail && e?.detail?.length > 0) {
    handleConsent(e.detail);
  }
};

const OneTrust = ({
  scriptId = '',
}) => {

  if (!scriptId || (process.env.NODE_ENV === 'development' && process.env.INCLUDE_ONETRUST_IN_DEV !== 'true')) return null;

  return (
    <Script
      charSet="UTF-8"
      data-domain-script={scriptId}
      id='klaviyo-onetrust-script'
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      strategy={ScriptStrategy.postHydrate}
    />
  );
};

export default OneTrust;
