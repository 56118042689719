import { I18nextProvider } from 'react-i18next';

import initI18n from './i18n';

import type { ReactNode } from 'react';

const InternationalizationProvider = ({ children, langCode }: { children: ReactNode; langCode: string; }) => {
  const i18n = initI18n(langCode);

  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  );
};

export default InternationalizationProvider;
