import {
  createInstance,
  enums,
  OptimizelyProvider,
  setLogLevel,
  type ReactSDKClient,
} from '@optimizely/react-sdk';

import { getCookieValue } from '@klv/shared/utils/cookies';
import { windowIsDefined } from '@klv/shared/utils/functions';
import { decodeAndParse, getPageExperimentInformation } from '@klv/shared/utils/google-tag-manager';

import {
  onDecision,
  setContentsquareExperimentIntegrations,
  setContentsquareExperimentVariables,
} from './utils';

import { type ReactNode } from 'react';

const SDK_KEY = process.env.GATSBY_OPTIMIZELY_SDK_KEY ?? '';

const ID_COOKIE_NAME = '_kvyo_mkt_id';

declare global {
  interface Window {
    _kvyo_mkt_exp_provider?: ReactSDKClient;
  }
}

const createOptimizelyInstance = (sdkKey: string) => {
  if (sdkKey && windowIsDefined()) {
    return createInstance({
      sdkKey,
    });
  }

  return false;
};

const getKlaviyoCookies = (cookies = '') => {
  if (cookies && cookies.length > 0) {
    return cookies
      .split(';')
      .filter((cookie) => cookie.includes('__kla_id'))
      .map((cookie) => cookie.trim().split('__kla_id=')?.[1] || '')
      .filter(Boolean);
  }

  return [];
};

const anyCookieHasAccountID = (cookies: string[]) => (
  cookies.some((cookie) => {
    try {
      const parsed = decodeAndParse(cookie);
      if (parsed?.AccountID) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  })
);

setLogLevel(process.env.NODE_ENV === 'production' ? 'error' : 'info');

const optimizely = createOptimizelyInstance(SDK_KEY);

if (optimizely) {
  if (windowIsDefined()) {
    window._kvyo_mkt_exp_provider = optimizely;
  }

  optimizely.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    (decision) => onDecision(decision, optimizely),
  );

  optimizely.onReady().then(() => {
    const pageExperiment = getPageExperimentInformation();
    if (pageExperiment) {
      setContentsquareExperimentVariables(pageExperiment['optimizely-ruleKey'], pageExperiment['optimizely-variationKey']);
      setContentsquareExperimentIntegrations();
    }
  });
}

export const Optimizely = ({
  children,
}: {
  children: ReactNode;
}) => {
  const id = getCookieValue(ID_COOKIE_NAME);
  const internalTester = getCookieValue('_kvyo_mkt_internal_tester');
  const isCustomer = anyCookieHasAccountID(getKlaviyoCookies(typeof document === 'undefined' ? '' : document.cookie));

  if (
    !id
    || !optimizely
  ) {
    return children;
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        attributes: {
          hasKlaviyoAccountId: isCustomer,
          internalTester: internalTester === '1',
        },
        id,
      }}
    >
      {children}
    </OptimizelyProvider>
  );
};
