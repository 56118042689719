export const ignoreErrors = [
  'Cannot read properties of undefined (reading',
  'Cannot read properties of null (reading',
  'Cannot read property ',
  'must be called from a top-level browsing context or allowed by the permission policy',
  /^Load failed$/,
  'We couldn\'t load',
  'The user denied permission to access the database',
  'Access is denied for this document',
  'undefined is not an object (evaluating',
  'Object Not Found Matching Id:',
  'Can\'t find variable: Chartboost',
  'Chartboost',
  'AbortError: AbortError',
  'ReferenceError: Can\'t find variable: __',
  'Failed to execute \'importScripts\' on \'WorkerGlobalScope\'',
  'Object captured as promise rejection with keys:',
  'not found. Not rendering React',
  'Qualified: ',
  'vimeo',
  'enumerateDevices() failed',
];
