import { Script, ScriptStrategy } from 'gatsby';

const KlaviyoOnsite = ({
  companyId = '',
}) => {

  if (!companyId) return null;

  return (
    <Script
      id='klaviyo-onsite-js'
      src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${companyId}`}
      strategy={ScriptStrategy.postHydrate}
    />
  );
};

export default KlaviyoOnsite;
