import { Optimizely } from './optimizely';

import type { ReactNode } from 'react';

const SDK_KEY = process.env.GATSBY_OPTIMIZELY_SDK_KEY ?? '';

export const ExperimentationProvider = ({
  children,
  includeExperimentation,
}: {
  children: ReactNode;
  includeExperimentation: boolean;
}) => {
  if (
    !includeExperimentation
    || !SDK_KEY
  ) {
    return children;
  }

  return (
    <Optimizely>
      {children}
    </Optimizely>
  );
};
