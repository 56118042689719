import InternationalizationProvider from '@klaviyo-mkt/gatsby-components/context/internationalization';

import KlaviyoOnsite from '../scripts/klaviyo';
import OneTrust from '../scripts/one-trust';

import type { GatsbySSR } from 'gatsby';
import type { IThemeOptions } from '../types';

export const convertLocaleToLangCode = (locale = '') => {
  const split = locale.split('-');
  if (split.length > 1) {
    return `${split[0]}-${split[1].toUpperCase()}`;
  }

  return locale;
};

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }, themeOptions: IThemeOptions) => {
  const { langCode = 'en-US' } = themeOptions;
  const { pageContext: { locale = '' } = {} } = (props as { pageContext?: { locale?: string; }; }) || {};
  
  return (
    <InternationalizationProvider langCode={convertLocaleToLangCode(locale) || langCode}>
      {element}
      <OneTrust scriptId='c9a82de7-400d-498b-b0db-126eb3e4e8e8' />
      <KlaviyoOnsite companyId={themeOptions.klaviyoCompanyId} />
    </InternationalizationProvider>
  );
};
