import AuthenticationContextProvider from './authentication';
import { ExperimentationProvider } from './experimentation';
import LocalizationContextProvider from './localization';
import ModalContextProvider from './modal';
import SavedJobsProvider from './saved-jobs';
import RecentJobsProvider from './recent-jobs';
import { EagerLoadingContextProvider } from './eager-loading';

import type { IRootContextProvider } from './types';

const RootContextProvider = ({
  children,
  includeExperimentation,
}: IRootContextProvider) => (
  <EagerLoadingContextProvider eagerLoad={false}>
    <AuthenticationContextProvider>
      <LocalizationContextProvider>
        <ModalContextProvider>
          <SavedJobsProvider>
            <RecentJobsProvider>
              <ExperimentationProvider includeExperimentation={includeExperimentation}>
                {children}
              </ExperimentationProvider>
            </RecentJobsProvider>
          </SavedJobsProvider>
        </ModalContextProvider>
      </LocalizationContextProvider>
    </AuthenticationContextProvider>
  </EagerLoadingContextProvider>
);


export default RootContextProvider;
