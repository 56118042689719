import axios from 'axios';

import { screenSize } from './screen-size';
import { getCookieValue } from './cookies';
import { gtmFormProgressEvent } from './google-tag-manager';

import type { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import type { FormikProps } from 'formik';
import type { DefaultTheme } from 'styled-components';
import type { IImage } from '@klv/atoms/image/types';
import type { IFormState, ISingleLineFormState, IInitialFormValues } from '@klv/organisms/form/types';
import type { IInitialSupportFormValues } from '@klv/organisms/form-support-request/types';
import type { IInitialAccountAccessFormValues } from '@klv/organisms/form-account-access/types';
import type { TDesignSystemColors } from '../styles/colors';
import type { TBreakpoints, TColorVariant, TColorVariantLegacy } from '../styles/types';


export const setHeaderTag = (headline = '') => (headline ? 'header' : '');

export const checkIfExternalURL = (url: string): boolean => (!/klaviyo\.com/.exec(url));

/**
 * @description Create a CSS clamp function for sizes.
 * Returns without a semi-colon at the end for more flexible use.
 * @source https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#aa-step-1
 */
export const clampSize = (minSize: number, maxSize: number) => {
  const bpKeys = Object.keys(screenSize);
  const maxScreenSize = screenSize[bpKeys[bpKeys.length - 1] as keyof typeof screenSize].size;
  const minScreenSize = screenSize[bpKeys[0] as keyof typeof screenSize].size;
  const slope = (maxSize - minSize) / (maxScreenSize - minScreenSize);
  const yAxisIntersection = -minScreenSize * slope + minSize;

  return (`clamp(${minSize}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxSize}rem)`);
};

const createSpacingCSS = (breakpoint: TBreakpoints, cssProps: string[], theme: DefaultTheme) => {
  const cssValues = cssProps.map((cssProp) => (`
    ${cssProp}: ${theme.screenSize[`s${breakpoint}`].gridHorizontalPadding}rem;
  `));

  switch (breakpoint) {
    case 380:
      return cssValues.join('\n');
    default:
      return (`
        ${theme.screenSize[`s${breakpoint}`].mediaQuery} {
          ${cssValues.join('\n')}
        }
      `);
  }
};

export const generateClassName = (classes: (string | false | 0 | undefined)[]) => classes.filter(Boolean).join(' ');

export const generateSpacingCSS = (cssProps: string[], theme: DefaultTheme, mediaQueries: TBreakpoints[] = []) => {
  const breakpoints = mediaQueries.length ? mediaQueries : theme.breakpoints;
  const values = breakpoints.map((breakpoint) => (
    createSpacingCSS(breakpoint as TBreakpoints, cssProps, theme)
  ));

  return values.join('\n\n');
};

export const getColorVariantFromBackground = (background: TColorVariant | TColorVariantLegacy | 'white') => (['charcoal', 'dark'].includes(background) ? 'light' : 'dark');

export const convertBackgroundToNamedColor = (background: TColorVariant | TColorVariantLegacy | 'white') => {
  if (background === 'dark') return 'charcoal';
  if (background === 'light') return 'lightSand';

  return background;
};

export const convertNamedColorToColorVariant = (background: TColorVariant | TColorVariantLegacy | 'white') => (['charcoal', 'dark'].includes(background) ? 'dark' : 'light');

export const getAuthorName = (
  name: string,
  firstName: string | undefined | null = '',
  lastName: string | undefined | null = '',
) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return name;
};

export const ucFirst = (text: string) => (`${text.charAt(0).toUpperCase()}${text.slice(1)}`);

export const hash = (s: string) => {
  let i;
  let h;
  // eslint-disable-next-line no-bitwise
  for (i = 0, h = 0xdeadbeef; i < s.length; i += 1) { h = Math.imul(h ^ s.charCodeAt(i), 2654435761); }
  // eslint-disable-next-line no-bitwise
  return (h ^ h >>> 16) >>> 0;
};

export const windowIsDefined = () => typeof window !== 'undefined';

/**
 * @param {object} image – An object to test for the localFile key, indicating it might have image data
 * @returns {boolean} – Whether the object contains the localFile key
 */

export const hasImage = (image?: IImage | null): boolean => (!!image?.localFile || !!image?.gatsbyImage);

/**
 *
 * @returns The string with the requested tags removed.
 * @description Removes specific tags from a string, but leaves the contents intact.
 */
export const stripTags = (text: string, tags: string[] = []) => {
  let updatedText = text;

  if (tags.length) {
    tags.forEach((tag) => {
      const pattern = RegExp(`</*${tag}>`, 'gi');
      updatedText = updatedText.replace(pattern, '');
    });
  } else {
    updatedText = updatedText.replace(/<\/*\w>/gi, '');
  }

  return updatedText;
};

export const calculateWidthFromColumns = (spanColumns: number, totalColumns: number, gutterWidth = '2.4rem') => (
  `calc((((100% - (${gutterWidth} * ${totalColumns - 1})) / ${totalColumns}) * ${spanColumns}) + (${gutterWidth} * ${spanColumns - 1}));`
);

/**
 * Get date in format of m-d-y
 * @return {string}
 */
export const getFormattedDate = (dateString: string) => {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

/**
* @returns {object} Object containing key value pairs of tracking query parameters from the URL
*/
export const getUrlTrackingParams = () => {
  let paramSource = '';
  if (windowIsDefined()) {
    paramSource = window.location.search;
  }
  const urlSearchParams = new URLSearchParams(paramSource);

  return {
    utm_source: urlSearchParams.get('utm_source') || '',
    utm_medium: urlSearchParams.get('utm_medium') || '',
    utm_campaign: urlSearchParams.get('utm_campaign') || '',
    utm_content: urlSearchParams.get('utm_content') || '',
    utm_term: urlSearchParams.get('utm_term') || '',
    gclid: urlSearchParams.get('gclid') || '',
    gclsrc: urlSearchParams.get('gclsrc') || '',
    detail: urlSearchParams.get('detail') || '',
    wbraid: urlSearchParams.get('wbraid') || '',
    lead_source: urlSearchParams.get('lead_source') || '',
    lead_source_detail: urlSearchParams.get('lead_source_detail') || '',
    gh_src: urlSearchParams.get('gh_src') || '',
    irclickid: urlSearchParams.get('irclickid') || '',
  };
};

export const getFirstPage = () => {
  try {
    const klaIdCookie = getCookieValue('__kla_id');
    if (klaIdCookie) {
      const decodedKlaId = JSON.parse(atob(klaIdCookie));
      if (decodedKlaId?.$referrer?.first_page) {
        const parsed = new URL(decodedKlaId?.$referrer?.first_page);
        return `${parsed.origin}${parsed.pathname}`;
      }
    }

    return '';
  } catch (error) {
    return '';
  }
};

export const httpSetHelper = (formik: FormikProps<IInitialFormValues> | FormikProps<IInitialSupportFormValues> | FormikProps<IInitialAccountAccessFormValues>, target: HTMLInputElement) => {
  if (target.value && !target.value.startsWith('http://') && !target.value.startsWith('https://')) {
    formik.setFieldValue(target.name, `http://${target.value}`, true);
    setTimeout(() => {
      formik.validateField(target.name);
    }, 100);
  }
};

export const handleFieldBlur = (event: SyntheticEvent, formik: FormikProps<IInitialFormValues>, setFormState: Dispatch<SetStateAction<IFormState>> | Dispatch<SetStateAction<ISingleLineFormState>>, formName: string) => {
  const target = event.target as HTMLInputElement;
  const friendlyNames: { [key: string]: string; } = {
    field_1: 'newsletter',
    field_16: 'usage',
    field_17: 'business_type',
  };
  const gtmFormProgressEventLabel = friendlyNames?.[target.name] || target.name;

  if (target.type === 'checkbox') {
    formik.handleChange(event);
    setFormState({ [target.name]: target.checked } as IFormState);
    gtmFormProgressEvent(formName, gtmFormProgressEventLabel);
  } else {
    const { value } = event.target as HTMLInputElement;
    if (target.name === 'company_website' || target.name === 'company_url') {
      if (formName === 'requestDemo') {
        formik.setFieldValue('company', `${target.value}`, true);
        httpSetHelper(formik, target);
      }
      httpSetHelper(formik, target);
    }
    formik.handleBlur(event);
    setFormState({ [target.name]: value } as IFormState);
    if (value) gtmFormProgressEvent(formName, gtmFormProgressEventLabel);
  }
};

export const submitForm = async (url: string, values: IInitialFormValues) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => formData.append(key, value as Blob));
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

/**
 * @returns {string} - A string in Title Case.
 */
export const camelCaseToTitleCase = (text: string) => text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

/**
 * @returns {object} - An object of key/value pairs where the keys are color names in camelCase and the values are color names in Title Case.
 */

export const generateColorLabels = (designSystemColors: TDesignSystemColors) => {
  const colors: { [key: string]: string; } = {};
  Object.keys(designSystemColors).forEach((color) => {
    colors[color] = camelCaseToTitleCase(color);
  });

  return colors;
};


export const formatCategoryLink = (langCode: string, link: string) => {
  if (!link) { 
    return '';
  }
  let formattedLink = link;
  if (langCode && formattedLink.startsWith(`https://www.klaviyo.com/${langCode}`)) {
    formattedLink = link.replace(`https://www.klaviyo.com/${langCode}`, '');
  } else if (formattedLink.startsWith('https://www.klaviyo.com')) {
    formattedLink = link.replace('https://www.klaviyo.com', '');
  }
  
  return formattedLink;
};

export const formatDepartmentName = (name: string) => {
  return name.toLowerCase().split(' ').map(
    word => {
      const formattedWord = word.charAt(0).toUpperCase() + word.substring(1);
      return formattedWord === ('It') ? 'IT' : formattedWord;
    },
  ).join(' ');
};


export const styleBadge = (html: string | null | undefined) => {
  if (!html) {
    return '';
  }
  return html.replace(/\[badge\](.*?)\[\/badge\]/g, '<span class="mce-badge mce-poppyShade">$1</span>');
};
