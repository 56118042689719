import * as Sentry from '@sentry/gatsby';

import { disallowedFilenames } from './disallowedFilenames';
import { ignoreErrors } from './ignoreErrors';

Sentry.init({
  beforeSend(event) {
    if (event.exception?.values?.[0]?.stacktrace?.frames?.some((f) => disallowedFilenames.some((df) => f.filename?.startsWith(df)))) {
      return null;
    }

    return event;
  },
  dsn: __SENTRY_DSN__,
  environment: process.env.NODE_ENV,
  ignoreErrors,
  sampleRate: __SENTRY_SAMPLE_RATE__,
  tracesSampleRate: __SENTRY_TRACES_SAMPLE_RATE__,
});
