import {
  createContext, useContext, useMemo, useState,
} from 'react';

import type { ReactNode } from 'react';
import type { IModalStore } from './types';

const initialState = {
  visibleModalId: false,
};

const initialStore = {
  modalState: initialState,
  close: () => { },
  open: () => { },
};

export const ModalContext = createContext<IModalStore>(initialStore);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used in a child of the ModalContextProvider');
  }
  return context;
};

const ModalContextProvider = ({ children }: { children: ReactNode; }) => {
  const [state, setState] = useState<IModalStore['modalState']>(initialState);

  const memoizedStore = useMemo(() => ({
    modalState: state,
    close: () => {
      setState(initialState);
    },
    open: (id: string) => {
      setState({
        visibleModalId: id,
      });
    },
  }), [state]);

  return (
    <ModalContext.Provider value={memoizedStore}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
