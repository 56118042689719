import { getUrlTrackingParams } from './functions';
import { hasStorageAvailable } from './storage';


export const saveItemToSessionStorage = (key: string, value: string) => {
  if (!key || !value) return false;

  if (hasStorageAvailable('sessionStorage')) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const readLeadSourcePropertiesFromSessionStorage = () => {
  const leadSourceObject = {
    lead_source: '',
    lead_source_detail: '',
  };

  try {
    if (hasStorageAvailable('sessionStorage')) {
      const lead_source = sessionStorage.getItem('lead_source') || '';
      const lead_source_detail = sessionStorage.getItem('lead_source_detail') || '';

      if (lead_source) {
        leadSourceObject.lead_source = JSON.parse(lead_source);
      }

      if (lead_source_detail) {
        leadSourceObject.lead_source_detail = JSON.parse(lead_source_detail);
      }
    }
  } catch {
    return leadSourceObject;
  }

  return leadSourceObject;
};

export const handleLeadSource = () => {
  const { lead_source, lead_source_detail } = getUrlTrackingParams();

  if (lead_source) {
    saveItemToSessionStorage('lead_source', lead_source);
  }

  if (lead_source_detail) {
    saveItemToSessionStorage('lead_source_detail', lead_source_detail);
  }
};
