import axios from 'axios';

import type { ILocalizationStore } from '../types';

const getVisitorGeolocation = async () => {
  const axiosInstance = axios.create();
  try {
    const response = await axiosInstance.get('https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location/geofeed');
    const { status } = response;
    if (status === 200) {
      if (response?.data) {
        const locationData: ILocalizationStore['localizationState'] = {
          continent: response?.data?.continent,
          country: response?.data?.country,
          state: response?.data?.state,
          geodataLoaded: true,
        };

        return locationData;
      }
    }

    return false;
  } catch (error) {
    return false;
  }
};

export default getVisitorGeolocation;
