export const breakpoints = [380, 576, 768, 992, 1024, 1200, 1366] as const;

export const generateMediaQuery = (size: number) => `@media screen and (min-width: ${size * 10}px)`;

export const screenSize = {
  s380: {
    gridColumnGap: 2.4,
    gridColumns: 8,
    gridHorizontalPadding: 2,
    mediaQuery: generateMediaQuery(38),
    size: 38,
  },
  s576: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 3.6,
    mediaQuery: generateMediaQuery(57.6),
    size: 57.6,
  },
  s768: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 3.6,
    mediaQuery: generateMediaQuery(76.8),
    size: 76.8,
  },
  s992: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 6.4,
    mediaQuery: generateMediaQuery(99.2),
    size: 99.2,
  },
  s1024: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 4.4,
    mediaQuery: generateMediaQuery(102.4),
    size: 102.4,
  },
  s1200: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 6,
    mediaQuery: generateMediaQuery(120),
    size: 120,
  },
  s1366: {
    gridColumnGap: 2.4,
    gridColumns: 12,
    gridHorizontalPadding: 8.3,
    mediaQuery: generateMediaQuery(136.6),
    size: 136.6,
  },
};
