import { getUrlTrackingParams } from './functions';
import { hasStorageAvailable } from './storage';

const sessionStorageTrackingParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'detail',
  'wbraid',
  'gh_src',
] as const;

const localStorageTrackingParams = [
  'irclickid',
] as const;

export const getStoredTrackingParams = () => {
  const paramsObject = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    detail: '',
    wbraid: '',
    gh_src: '',
    irclickid: '',
  };

  try {
    if (hasStorageAvailable('sessionStorage')) {
      sessionStorageTrackingParams.forEach((param) => {
        const storedParam = sessionStorage.getItem(param);
        if (storedParam) {
          paramsObject[param] = JSON.parse(storedParam);
        }
      });
    }

    if (hasStorageAvailable('localStorage')) {
      localStorageTrackingParams.forEach((param) => {
        const storedParam = localStorage.getItem(param);
        if (storedParam) {
          paramsObject[param] = JSON.parse(storedParam);
        }
      });

      return paramsObject;
    }
  } catch {
    return paramsObject;
  }

  return paramsObject;
};

export const storeUrlTrackingParams = () => {
  const urlTrackingParams = getUrlTrackingParams();

  if (hasStorageAvailable('sessionStorage')) {
    sessionStorageTrackingParams.forEach((param) => {
      if (urlTrackingParams[param]) {
        sessionStorage.setItem(param, JSON.stringify(urlTrackingParams[param]));
      }
    });
  }

  if (hasStorageAvailable('localStorage')) {
    localStorageTrackingParams.forEach((param) => {
      if (urlTrackingParams[param]) {
        localStorage.setItem(param, JSON.stringify(urlTrackingParams[param]));
      }
    });
  }
};
