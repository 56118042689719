import { createContext, useContext } from 'react';
import type { IEagerLoadingContextProviderProps, IEagerLoadingContextValue } from './types';


// Create the context
const EagerLoadingContext = createContext<IEagerLoadingContextValue | undefined>(undefined);

// Custom hook to access the context value
export const useEagerLoadingContext = () => {
  const context = useContext(EagerLoadingContext);
  if (!context) {
    throw new Error('useEagerLoadingContext must be used within a EagerLoadingContextProvider');
  }
  return context.eagerLoad ? 'eager' : 'lazy';
};


export const EagerLoadingContextProvider: React.FC<IEagerLoadingContextProviderProps> = ({ eagerLoad, children }) => {
  return <EagerLoadingContext.Provider value={{ eagerLoad }}>{children}</EagerLoadingContext.Provider>;
};
