/* eslint-disable @typescript-eslint/no-explicit-any */
import RootContextProvider from '@klaviyo-mkt/gatsby-components/context';
import { Location } from '@gatsbyjs/reach-router';

import type { GatsbySSR } from 'gatsby';
import type { IThemeOptions } from '@klaviyo-mkt/gatsby-theme-core/src/types';

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }, pluginOptions: IThemeOptions) => {
  const { includeExperimentation = false } = pluginOptions;
  return (
    <Location>
      {() => (
        <RootContextProvider
          includeExperimentation={includeExperimentation}
        >
          {element}
        </RootContextProvider>
      )}
    </Location>
  );
};
