import { createContext, useState } from 'react';
import { hasStorageAvailable } from '@klv/shared/utils/storage';

import type { ReactNode } from 'react';
import type { IRecentJobsContextType } from './types';

const initialState: number[] = hasStorageAvailable() ? JSON.parse(localStorage.getItem('recentJobs') || '[]') : [];

export const RecentJobsContext = createContext<IRecentJobsContextType | null>(null);

const RecentJobsProvider = ({ children }: { children: ReactNode; }) => {
  const [state, setState] = useState(initialState);

  const addRecentJob = (jobId: number) => {
    setState((prevState) => {
      const jobIndex = prevState.indexOf(jobId);

      if (jobIndex === -1) {// not in our recent list, add it
        prevState.unshift(jobId);
        if (prevState.length > 5) {
          prevState.pop();
        }
      } else if (jobIndex > 0) {// It's in our current list, move it to the top.
        prevState.splice(jobIndex, 1);
        prevState.unshift(jobId);
      }

      localStorage.setItem('recentJobs', JSON.stringify(prevState));
      return prevState;
    });
  };

  return (
    <RecentJobsContext.Provider value={{ recentJobs:state, addRecentJob }}>
      {children}
    </RecentJobsContext.Provider>
  );
};

export default RecentJobsProvider;
