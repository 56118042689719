import { gtmBasicEvent } from '@klaviyo-mkt/gatsby-components/shared/utils/google-tag-manager';

export const getGaId = () => {
  return window?.parent?.ga?.getAll?.()?.[0]?.get('clientId') || '';
};

export const generateUtmsObject = (searchParams: URLSearchParams) => {
  const utms: { [x: string]: string; } = {};
  searchParams.forEach((value, key) => {
    if (key.startsWith('utm') || key.startsWith('dclid') || key.startsWith('gclid')) {
      utms[key] = value;
    }
  });
  utms.gclid = `${utms?.gclid || ''}:${getGaId()}`;
  return utms;
};

export const generateHiddenFieldsObject = (metaData: { [x: string]: string; }) => {
  const hiddenFields: { [x: string]: string; } = {};
  for (const [key, value] of Object.entries(metaData)) {
    if (typeof value === 'string' && key.startsWith('RECENT_')) {
      hiddenFields[key.replace('RECENT_', '')] = value;
    }
  }
  return hiddenFields;
};

export const onFormSubmit = (e: CustomEvent) => {
  const searchParams = new URLSearchParams(window.location.search);
  if (e.detail.type === 'submit') {
    window.klaviyo.track('Marketing Website Activity', {
      '$email': e.detail.metaData.$email,
      'key': e.detail.formId,
      'page': window.parent.location.href,
      ...generateUtmsObject(searchParams),
      ...generateHiddenFieldsObject(e.detail.metaData),
    }).catch((err) => console.error('Error submitting form tracking: ', err));
  }
};

export const handleKlaviyoInternationalSitePrompt = (e: CustomEvent) => {
  const validFormEvents = [
    'close',
    'submit',
  ];

  const internationalSitePromptFormData = {
    WkNUX4: 'fr',
    WMtinx: 'uk',
  } as const;

  type TFormIds = keyof typeof internationalSitePromptFormData;

  const formIds = Object.keys(internationalSitePromptFormData) as Array<TFormIds>;

  if (
    formIds.includes(e.detail.formId)
    && validFormEvents.includes(e.detail.type)
  ) {
    gtmBasicEvent({
      action: (e.detail.type === 'close') ? 'decline' : 'accept',
      category: 'country selector',
      label: internationalSitePromptFormData[e.detail.formId as TFormIds],
    });
  }
};
